import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import { Form } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { DATEPICKER } from 'components/cmp_form/cmp_form';
import { Button } from 'semantic-ui-react';
import Moment from 'moment';
import 'i18n';

import './crd_cee_tq_holders.css';


export default function CRD_CEE_TQ_HOLDERS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_cee_tq_holders, set_cee_tq_holders ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'issued' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_startdate, set_startdate ] = useState();
    const [ var_enddate, set_enddate ] = useState();

    const [ var_errors, set_errors ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!var_startdate) {
            set_startdate(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
        }
        if (!var_enddate) {
            set_enddate(new Date());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let errors = [];
        if (var_startdate && var_enddate && Moment(var_enddate).format('YYYY-MM-DD') < Moment(var_startdate).format('YYYY-MM-DD')) {
            errors.push({property: 'enddate', description: t(`To date cannot be before From date`)});
        }
        if (!var_startdate) {
            errors.push({property: 'startdate', description: t(`From date is required`)});
        }
        if (!var_enddate) {
            errors.push({property: 'enddate', description: t(`To date is required`)});
        }
        set_errors(errors);

        if (var_startdate && var_enddate && Moment(var_startdate).format('YYYY-MM-DD') <= Moment(var_enddate).format('YYYY-MM-DD')) {
            set_refreshtable(!var_refreshtable);
            populate_records({ ...var_populateconfig, offset: 0, filter: {} }, var_startdate, var_enddate);
        } else {
            set_totalrows(0);
            set_cee_tq_holders([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_startdate, var_enddate]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, startdate = var_startdate, enddate = var_enddate) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_post_cee_tq_holders(limit, offset, sortby, sortorder, filter, startdate, enddate);
            set_totalrows(results.totalrows);
            set_cee_tq_holders(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_post_cee_tq_holders(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, var_startdate, var_enddate, filtername)).results;
        } catch (e) {
            console.log(e);
        }

    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_post_cee_tq_holders(limit, offset, sortby, sortorder, filter, var_startdate, var_enddate)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_cee_tq_holders(limit, offset, sortby, sortorder, filter, startdate, enddate, filtername) {
        return API.post('reports', `/post-cee-tq-holders`,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone,
                    startdate: Moment(startdate).format('YYYY-MM-DD'),
                    enddate: Moment(enddate).format('YYYY-MM-DD')
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_startdate(e, { value }) {
        set_startdate(value);
    }

    function onChange_enddate(e, { value }) {
        set_enddate(value);
    }

    function onClick_individual(id) {
        navigate(`/individuals/${id}`);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_reports_cee_tq_holders'>
                <Table id='cee_tq_holders' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('CEE_TQ holders')} downloadfunction={download_records}
                        onChange={populate_records}>
                    <Table.OptionsButtons>
                        <Form className='cee_tq_holders_optionbuttons' id='form_reports_cee_tq_holders' aria-labelledby='hdr_reports_cee_tq_holders'>
                            <div className='datepicker_wrapper'>
                                <div className='datepicker_label text--sm-medium'>{t('From')}</div>
                                <DATEPICKER
                                    property='startdate'
                                    value={var_startdate}
                                    onChange={onChange_startdate}
                                    placeholder={t('YYYY-MM-DD')}
                                    minproperty={null}
                                    maxproperty={null}
                                    errors={var_errors}
                                />
                            </div>
                            <div className='datepicker_wrapper'>
                                <div className='datepicker_label text--sm-medium'>{t('To')}</div>
                                <DATEPICKER
                                    property='enddate'
                                    value={var_enddate}
                                    onChange={onChange_enddate}
                                    placeholder={t('YYYY-MM-DD')}
                                    minproperty={null}
                                    maxproperty={null}
                                    errors={var_errors}
                                />
                            </div>
                        </Form>
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='verification_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='issued' datatype='datetime' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_cee_tq_holders.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <div className={'badge text--sm-medium  ' +
                                        (   item.verification_status === 'Valid' ?
                                                'badge--green'
                                            : item.verification_status === 'Invalid' ?
                                                'badge--red'
                                            :  item.verification_status === 'In Progress' ?
                                                'badge--yellow'
                                            : 'badge--gray')}>
                                        {item.verification_status_display}
                                    </div>
                                </Table.Cell>
                                <Table.Cell >
                                    <Button className='tertiary' onClick={() => onClick_individual(item.individual_id)}>
                                        <Table.CellContentTwoLine primary_text={<div className='text--anchor'>{item.individual_name}</div>} secondary_text={item.email} />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.credential_name}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.issued, true)}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>
        </>
    )

}
