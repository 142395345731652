import React, { useEffect, useState } from 'react';
import form_helper from 'libs/form-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_INPUT, FORM_SELECT, FORM_TEXTAREA, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import { useNavigate } from "react-router-dom";
import 'i18n';
import Processing from 'components/cmp_processing';
import API from 'libs/api-lib';


export default function CRD_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_verification_template, set_verification_template ] = useState({ refresh_seconds: 30 });
    const [ var_themes, set_themes ] = useState([])
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    const refresh_seconds_options = [
        { text: t('15 seconds'), value: 15 },
        { text: t('30 seconds'), value: 30 },
        { text: t('1 minute'), value: 60 },
        { text: t('2 minutes'), value: 120 },
        { text: t('5 minutes'), value: 300 }
    ];

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_themes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_themes() {
        let themes = await API_get_verification_theme_list();
        set_themes(themes.map(item => ({ value: item.id, text: item.name })));
    }

    async function insert_verification_template(verification_template) {
        set_processing(true);
        try {
            let verification_template_id = await API_post_verification_template(verification_template);
            navigate('/org/verifications/' + verification_template_id);
        } catch (exception) {
            console.log(exception);
            set_errors(t('An error has occurred'));
        } finally {
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_verification_theme_list() {
        return API.get('verifiable-credentials', '/get-verification-theme-list');
    }

    function API_post_verification_template(body) {
        return API.post('verifiable-credentials', '/post-verification-template', { body });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event, { name, value }) {
        let verification_template = { ...var_verification_template };
        verification_template[name] = value;
        set_verification_template(verification_template);
        set_errors(var_errors.filter(error => error.property !== name));
    }

    function onClick_save() {
        let errors = [];
        let verification_template = { ...var_verification_template };
        if (!form_helper.validate_required_string(verification_template.name)) {
            errors.push({ property: 'name', description: t('Name is required') });
        }
        if (!form_helper.validate_required_string(verification_template.title)) {
            errors.push({ property: 'title', description: t('Title is required') });
        }
        if (!form_helper.validate_required_string(verification_template.description)) {
            errors.push({ property: 'description', description: t('Description is required') });
        }
        if (!verification_template.verification_template_theme_id) {
            errors.push({ property: 'verification_template_theme_id', description: t('Theme is required') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        form_helper.cleanse_string_property(verification_template, 'name');
        form_helper.cleanse_string_property(verification_template, 'title');
        form_helper.cleanse_string_property(verification_template, 'description');
        set_verification_template(verification_template);

        insert_verification_template(verification_template);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_create_verification_template_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_templates' className='text--xl-medium'>{t('Template details')}</div>
                </div>
            </div>

            <Form className='card__content' id='form_create_verification_template_step1' aria-labelledby='hdr_create_verification_template_details'>

                {var_errors.length > 0 &&
                    <Message
                        error icon={<Icon name='error' className='icon'/>}
                        header={t('There are some errors with your inputs')}
                    />
                }

                <FORM_TOGGLE
                    label={t('Status')}
                    value='DRAFT'
                    options={[{ value: 'PUBLISH', text: t('Publish') }, { value: 'DRAFT', text: t('Draft') }]}
                    message_type='WARNING'
                    disabled={true}
                    message_text={t('This verification template will remain in draft mode until credentials have been added.')}
                />

                <FORM_INPUT
                    property='name'
                    label={t('Name')}
                    value={var_verification_template.name}
                    onChange={onChange_input}
                    placeholder={t('Name')}
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                    description={t('For internal reference within the system only.')}
                />

                <FORM_INPUT
                    property='title'
                    label={t('Title')}
                    value={var_verification_template.title}
                    onChange={onChange_input}
                    placeholder={t('Title')}
                    maxLength={200}
                    disabled={false}
                    errors={var_errors}
                    description={t('The title of the request to be displayed on the verification page.')}
                />

                <FORM_TEXTAREA
                    property='description'
                    label={t('Description')}
                    value={var_verification_template.description}
                    onChange={onChange_input}
                    placeholder={t('Description')}
                    maxLength={500}
                    disabled={false}
                    errors={var_errors}
                    description={t('This information will be shown on the verification page to inform users of the requested credentials and/or the purpose behind the request.')}
                />

                <FORM_SELECT
                    property='verification_template_theme_id'
                    label={t('Theme')}
                    value={var_verification_template.verification_template_theme_id || ''}
                    onChange={onChange_input}
                    placeholder={t('Select')}
                    options={var_themes}
                    disabled={false}
                    errors={var_errors}
                    description={t('Customize the look of the verification template by choosing a theme.')}
                />

                <FORM_SELECT
                    property='refresh_seconds'
                    label={t('Page refresh')}
                    value={var_verification_template.refresh_seconds}
                    onChange={onChange_input}
                    placeholder={t('Select')}
                    options={refresh_seconds_options}
                    description={t('Set the delay before the page refreshes with a new QR code after a presentation.')}
                />

            </Form>

            <div className='card__footer'>
                <Button className='primary' onClick={onClick_save}>{t('Save template')}</Button>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}