import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import { FORM_DETAILSGROUP, FORM_TOGGLE, FORM_INPUT, FORM_TEXTAREA, FORM_SELECT } from 'components/cmp_form/cmp_form';
import API from 'libs/api-lib';
import form_helper from 'libs/form-lib';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';

import MDL_THEME_PREVIEW from './mdl_theme_preview/mdl_theme_preview';


function CRD_DETAILS({ activetab, verification_template, verification_template_credentials, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const [ var_cardstatus, set_cardstatus ] = useState('VIEW');
    const [ var_working_template, set_working_template ] = useState({});
    const [ var_themes, set_themes ] = useState([]);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_preview_open, set_preview_open ] = useState(false);

    const refresh_seconds_options = [
        { text: t('{{seconds}} seconds', { seconds: 15 }), value: 15 },
        { text: t('{{seconds}} seconds', { seconds: 30 }), value: 30 },
        { text: t('1 minute'), value: 60 },
        { text: t('{{minutes}} minutes', { minutes: 2 }), value: 120 },
        { text: t('{{minutes}} minutes', { minutes: 5 }), value: 300 }
    ];

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'DETAILS' && verification_template.id) {
            set_cardstatus('VIEW');
            set_message_text(null);
            set_message_type(null);
            populate_themes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, verification_template.id]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_themes() {
        let themes = await API_get_verification_theme_list();
        set_themes(themes.map(item => ({ value: item.id, text: item.name })));
    }

    function badge_info(status) {
        switch (status) {
            case 'DRAFT':
                return { value: t('Draft'), badgecolor: 'badge--yellow' };
            case 'ACTIVE':
                return { value: t('Published'), badgecolor: 'badge--green' };
            case 'ARCHIVE':
                return { value: t('Unpublished'), badgecolor: 'badge--gray' };
            default:
                console.error('Unknown status');
        }
    }

    function status_toggle() {
        // rules:
        // if template is in draft status only draft and publish is visible, publish button should be disabled if the template does not have a credential added
        // if template is published or unpublished, buttons should be unpublished and publish
        const disabled = !(verification_template_credentials?.length > 0);
        const options = verification_template.status === 'DRAFT'
            ? [{ value: 'DRAFT', text: t('Draft') }, { value: 'ACTIVE', text: t('Published') }]
            : [{ value: 'ACTIVE', text: t('Published') }, { value: 'ARCHIVE', text: t('Unpublished') }]
        return { disabled, options };
    }

    async function update_template(updated_template) {
        set_processing(true);
        try {
            await API_put_verification_template(updated_template);
            onChange && await onChange();
            set_message_type('SUCCESS');
            set_message_text(t('Details saved successfully.'));
            set_cardstatus('VIEW');
        } catch (e) {
            set_message_type('ERROR');
            set_message_text(t('There was a problem saving.  Please try again later.'));
            console.log(e);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------


    function API_get_verification_theme_list() {
        return API.get('verifiable-credentials', '/get-verification-theme-list');
    }

    function API_put_verification_template(updated_template) {
        return API.put('verifiable-credentials', '/put-verification-template', { body: updated_template });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_message_text(null);
        set_working_template({ ...verification_template });
        set_cardstatus('EDIT');
    }

    function onClick_previewTemplate() {
        set_preview_open(true);
    }

    function onChange_input(event, { name, value }) {
        set_message_text(null);
        let template = { ...var_working_template };
        template[name] = value;
        set_working_template(template);
        set_errors(var_errors.filter(error => error.property !== name));
    }

    async function onClick_save() {
        if (var_processing) return;
        set_message_text(null);
        let updated_template = { ...var_working_template };
        let errors = [];
        //validation
        if (!form_helper.validate_required_string(updated_template.name)) {
            errors.push({ property: 'name', description: t('Please add a name.') });
        }
        if (!form_helper.validate_required_string(updated_template.title)) {
            errors.push({ property: 'title', description: t('Please add a title.') });
        }
        if (!form_helper.validate_required_string(updated_template.description)) {
            errors.push({ property: 'description', description: t('Please add a description.') });
        }
        if (!form_helper.validate_positive_number(updated_template.refresh_seconds)) {
            errors.push({ property: 'refresh_seconds', description: t('Please select an option.') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(updated_template, 'name');
        form_helper.cleanse_string_property(updated_template, 'title');
        form_helper.cleanse_string_property(updated_template, 'description');

        set_working_template(updated_template);
        await update_template(updated_template);

    }

    function onClick_cancel() {
        set_errors([]);
        set_message_text(null);
        set_cardstatus('VIEW');
    }

    function get_refresh_seconds_display(refresh_seconds) {
        if (!refresh_seconds) {
            return;
        } else if (refresh_seconds < 60) {
            return t('{{refresh_seconds}} seconds', { refresh_seconds });
        } else if (refresh_seconds === 60) {
            return t('1 minute');
        } else {
            return t('{{minutes}} minutes', { minutes: refresh_seconds / 60 });
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_verification_template_details' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Template details')}</div>
                    </div>
                    {var_cardstatus === 'VIEW' &&
                        <div className='card__header__right card__header__btns'>
                            <Button id='btn_edit_template' className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                        </div>
                    }
                </div>

                <div className='card__content'>
                    {var_message_text &&
                        <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                            icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                            header={var_message_text} />
                    }

                    {verification_template_credentials.length === 0 && (verification_template.status === 'DRAFT' || verification_template.status === 'ARCHIVE') &&
                        <Message warning icon={<Icon name='warning' className='icon' />} header={t('Credentials must be added to this verification template before it can be published.')} />
                    }

                    {!!verification_template.id && (var_cardstatus === 'VIEW' ? render_view() : render_edit())}

                    <div className='card__footer--mobilebtns'>
                        <Button id='btn_mobile_edit_template' className='primary' onClick={onClick_edit}>{t('Edit')}</Button>
                    </div>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />
            </div>

            <MDL_THEME_PREVIEW is_open={var_preview_open} verification_template={verification_template} onClose={() => set_preview_open(false)} />
        </>
    );

    function render_view() {
        return (
            <>
                <FORM_DETAILSGROUP label={t('Status')} type='badge' { ...badge_info(verification_template.status) } />
                <FORM_DETAILSGROUP label={t('Name')} value={verification_template.name} />
                <FORM_DETAILSGROUP label={t('Title')} value={verification_template.title} />
                <FORM_DETAILSGROUP label={t('Description')} value={verification_template.description} />
                <FORM_DETAILSGROUP label={t('Theme')} value={verification_template.verification_template_theme_name} />
                <FORM_DETAILSGROUP label={t('Page refresh')} value={get_refresh_seconds_display(verification_template.refresh_seconds)} />
                <FORM_DETAILSGROUP label={t('Template')} type='internallink' value={onClick_previewTemplate} linktext={t('Preview template')} />
            </>
        );
    }

    function render_edit() {
        return (
            <>
                <FORM_TOGGLE label={t('Status')} value={var_working_template.status} onChange={(value) => onChange_input(null, { name: 'status', value })} { ...status_toggle() } />

                <FORM_INPUT
                    property='name'
                    label={t('Name')}
                    placeholder={t('Name')}
                    value={var_working_template.name}
                    onChange={onChange_input}
                    maxLength={100}
                    errors={var_errors}
                    description={t('For internal reference within the system only.')}
                />

                <FORM_INPUT
                    property='title'
                    label={t('Title')}
                    placeholder={t('Title')}
                    value={var_working_template.title}
                    onChange={onChange_input}
                    maxLength={200}
                    errors={var_errors}
                    description={t('The title of the request to be displayed on the verification page.')}
                />

                <FORM_TEXTAREA
                    property='description'
                    label={t('Description')}
                    placeholder={t('Description')}
                    value={var_working_template.description}
                    onChange={onChange_input}
                    maxLength={500}
                    errors={var_errors}
                    description={t('This information will be shown on the verification page to inform users of the requested credentials and/or the purpose behind the request.')}
                />

                <FORM_SELECT
                    property='verification_template_theme_id'
                    label={t('Theme')}
                    placeholder={t('Default theme')}
                    value={var_working_template.verification_template_theme_id}
                    onChange={onChange_input}
                    options={var_themes}
                    errors={var_errors}
                    description={t('Customize the look of the verification template by choosing a theme.')}
                />

                <FORM_SELECT
                    property='refresh_seconds'
                    label={t('Page refresh')}
                    value={var_working_template.refresh_seconds}
                    onChange={onChange_input}
                    placeholder={t('Select')}
                    options={refresh_seconds_options}
                    errors={var_errors}
                    description={t('Set the delay before the page refreshes with a new QR code after a presentation.')}
                />

                <div className='card__footer'>
                    <Button id='btn_save_template' className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    <Button id='btn_cancel_template' className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                </div>
            </>
        );
    }

}

CRD_DETAILS.propTypes = {
    activetab: propTypes.string.isRequired,
    verification_template: propTypes.object.isRequired,
    onChange: propTypes.func
};

export default CRD_DETAILS;