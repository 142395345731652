import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import { Table as SemanticTable, Button, Popup } from 'semantic-ui-react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './cmp_table.css';
import Icon from '../cmp_icon';
import CMP_OPTIONSBUTTONS from './children/cmp_optionsbuttons';
import CMP_DISPLAYROWS from './children/cmp_displayrows';
import CMP_PAGINATION from './children/cmp_pagination';
import CMP_REFRESH from './children/cmp_refresh';
import CMP_EXPORT from './children/cmp_export';
import CMP_ROWSPERPAGE from './children/cmp_rowsperpage';
import CMP_SEARCH from './children/cmp_search';
import CMP_FILTER from './children/cmp_filter';

import CMP_CELLCONTENT_TWOLINE from './children/cmp_cellcontent_twoline';

export const default_populateconfig = { maxfilteritems: 1000, limit: 10, offset: 0, sortby: null, sortorder: 'ascending', filter: {} };


function Table({
    // general fields
    className, loading, ready, loadingerror = false, lockcolumns = 0, refresh = false, totalrows,
    // config
    populateconfig,
    // filter fields
    populatefilterfunction,
    // download fields
    downloadname, downloadfunction,
    // events
    onChange,
    // table control view
    hide_tablecontrols = false,
    // export configurations
    downloadconfig = null,
    // other
    ...props}) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_windowsize, set_windowsize ] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [ var_filterinformation, set_filterinformation ] = useState([]);
    const [ var_internal_filter, set_internal_filter ] = useState({ search_term: '', search_fields: [], filters: []});
    const [ var_throttled_config, set_throttled_config ] = useState(null);

    let { offset, limit, sortby, sortorder } = populateconfig;
    let columnWidth = 0;
    let columnSelected = null;
    let mousePosition = null;

    let header_row = props.children.find(item => item.type.name === Header.name);
    let header_cells = header_row.props.children.props.children.type?.name === HeaderCell.name
        ? [header_row.props.children.props.children]
        : header_row.props.children.props.children.filter(item => item.type?.name === HeaderCell.name);
    let column_count = header_cells.length;
    let fields = header_cells.map(item => item.props.field);
    let export_downloadconfig = downloadconfig || header_cells.filter(item => item.props.field).map(item => ({
        field: item.props.field,
        datatype: item.props.datatype,
        label: item.props.children
    }));
    let body = props.children.find(item => item.type?.name === Body.name);
    let options_buttons = props.children.find(item => item.type?.name === CMP_OPTIONSBUTTONS.name);
    let custom_props = {
        loading,
        ready,
        loadingerror,
        lockcolumns,
        offset,
        limit,
        totalrows,
        sortby,
        sortorder,
        fields,
        windowsize: var_windowsize,
        onChange_sort,
        populatefilterfunction,
        filterinformation: var_filterinformation,
        onChange_filter
    };
    let newprops = {...props};
    newprops.children = null;

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        // handle changes in window size
        const debounced_handle_resize = _.debounce(() => {
            set_windowsize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }, 1000);

        window.addEventListener('resize', debounced_handle_resize);

        return () => window.removeEventListener('resize', debounced_handle_resize);
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, []);

    useEffect(() => {
        // if refreshing then clear the filters and filter information
        let search_fields = header_cells.reduce((acc, item) => item.props.field && item.props.datatype === 'text'
                ? acc.concat(item.props.field)
                : acc, []);
        set_internal_filter({ search_term: '', search_fields, filters: [] });
        set_filterinformation([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    useEffect(() => {
        // for throttline changes to filters, fire the onChange event
        var_throttled_config && onChange && onChange(var_throttled_config);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_throttled_config]);


    //  functions ------------------------------------------------------------------------------------------------------

    function onMouseDown(event, column) {
        event.preventDefault(); // prevent selection start (browser action)

        columnSelected = column;
        columnWidth = document.querySelector('.header_'+column).offsetWidth;
        mousePosition = event.pageX;

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    }

    function onMouseMove(event) {
        let newColumnWidth;
        let minWidth = document.querySelector('.header_' + columnSelected + ' .table__headercell_text span').offsetWidth+72;

        if (mousePosition >= event.pageX) {
            newColumnWidth = columnWidth - (mousePosition - event.pageX);
        } else {
            newColumnWidth = columnWidth + (event.pageX - mousePosition);
        }
        if (newColumnWidth < minWidth) newColumnWidth = minWidth;

        document.querySelector('.header_' + columnSelected).style.width = newColumnWidth+'px';
        document.querySelector('#' + columnSelected).style.width = newColumnWidth+'px';
    }

    function onMouseUp() {
        document.removeEventListener('mouseup', onMouseUp);
        document.removeEventListener('mousemove', onMouseMove);
        columnSelected = null;
        mousePosition = 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttle_send_filter_event = useCallback(_.throttle(config => set_throttled_config(config), 1000), []);
    function execute_filtersearch(filterinformation) {
        let filter = { ...var_internal_filter, filters: [] };
        filterinformation.filter(item => item.applied).forEach(item => {
            switch (item.filtertype) {
                case 'date':
                    let { startdate, enddate } = item;
                    filter.filters.push(
                        startdate && enddate
                            ? {field: item.name, operator: 'between', valueFrom: startdate, valueTo: enddate}
                            : startdate
                            ? {field: item.name, operator: 'gte', value: startdate}
                            : {field: item.name, operator: 'lte', value: enddate}
                    );
                    break;
                case 'number':
                    let { minnumber, maxnumber } = item;
                    filter.filters.push(
                        minnumber !== null && maxnumber !== null
                            ? {field: item.name, operator: 'between', valueFrom: minnumber, valueTo: maxnumber}
                            : minnumber !== null
                            ? {field: item.name, operator: 'gte', value: minnumber}
                            : {field: item.name, operator: 'lte', value: maxnumber}
                    );
                    break;
                case 'option':
                    let blankSelected = item.options.some(option => option.selected && option.blank);
                    let valuesSelected = item.options.reduce((acc, option) => option.selected && !option.blank ? acc.concat(option.value) : acc, []);
                    let allOthersSelected = !item.options.some(option => !option.selected && !option.blank);
                    let blankText = item.datatype === 'text' ? 'Blank' : 'Null';
                    if (blankSelected && valuesSelected.length > 0) {
                        filter.filters.push({field: item.name, operator: 'oneOfOr'+blankText, values: valuesSelected.map(option => option)});
                    } else if (blankSelected) {
                        filter.filters.push({field: item.name, operator: blankText.toLowerCase()});
                    } else if (allOthersSelected) {
                        filter.filters.push({field: item.name, operator: 'not'+blankText});
                    } else if (valuesSelected.length > 0) {
                        filter.filters.push({field: item.name, operator: 'oneOf', values: valuesSelected.map(option => option)});
                    } else {
                        filter.filters.push({field: item.name, operator: blankText.toLowerCase()});
                        filter.filters.push({field: item.name, operator: 'not'+blankText});
                    }
                    break;
                case 'text':
                    filter.filters.push({field: item.name, operator: 'contains', value: item.searchterm})
                    break;
                default:
                    throw new Error('Unknown filter type');
            }
        });
        set_internal_filter(filter);
        throttle_send_filter_event({ ...populateconfig, offset: 0, filter });
    }

    function clear_filters() {
        let new_filter = { ...var_internal_filter, filters: [] };
        set_internal_filter(new_filter);
        throttle_send_filter_event({ ...populateconfig, offset: 0, filter: new_filter });
        let new_filterinformation = [...var_filterinformation];
        new_filterinformation.filter(item => item.applied).forEach(item => {
            switch (item.filtertype) {
                case 'date':
                    item.startdate = null;
                    item.enddate = null;
                    break;
                case 'number':
                    item.minnumber = null;
                    item.maxnumber = null;
                    break;
                case 'option':
                    item.options.forEach(option => option.selected = true);
                    break;
                case 'text':
                    item.searchterm = '';
                    break;
                default:
                    throw new Error('Unknown filter type');
            }
            item.applied = false;
        });
        set_filterinformation(new_filterinformation);
    }

    function onKeyDown_clear_filters(e) {
        if (e.key === 'Enter') {
            let new_filter = { ...var_internal_filter, filters: [] };
            set_internal_filter(new_filter);
            throttle_send_filter_event({ ...populateconfig, offset: 0, filter: new_filter });
            let new_filterinformation = [...var_filterinformation];
            new_filterinformation.filter(item => item.applied).forEach(item => {
                switch (item.filtertype) {
                    case 'date':
                        item.startdate = null;
                        item.enddate = null;
                        break;
                    case 'number':
                        item.minnumber = null;
                        item.maxnumber = null;
                        break;
                    case 'option':
                        item.options.forEach(option => option.selected = true);
                        break;
                    case 'text':
                        item.searchterm = '';
                        break;
                    default:
                        throw new Error('Unknown filter type');
                }
                item.applied = false;
            });
            set_filterinformation(new_filterinformation);
        }
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_search(search_term) {
        let new_filter = {...var_internal_filter, search_term};
        set_internal_filter(new_filter);
        throttle_send_filter_event({ ...populateconfig, offset: 0, filter: new_filter });
    }

    function onChange_filter(filter, performsearch) {
        set_filterinformation(filter);
        performsearch && execute_filtersearch(filter);
    }

    function onClick_refresh() {
        onChange && onChange();
    }

    function onChange_rowsperpage(newlimit) {
        onChange && onChange({ ...populateconfig, offset: 0, limit: newlimit });
    }

    function onChange_page(newpage) {
        onChange && onChange({ ...populateconfig, offset: (newpage - 1) * limit });
    }

    function onChange_sort(sortfield, sort_ascending) {
        if (sort_ascending === undefined) {
            sort_ascending = (sortby !== sortfield || sortorder === 'descending');
        }
        let new_sortorder = sort_ascending ? 'ascending' : 'descending';
        if (sortfield === sortby && new_sortorder === sortorder) return;
        onChange && onChange({ ...populateconfig, offset: 0, sortby: sortfield, sortorder: new_sortorder });
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='table__options'>
                <div className='table__options__buttons'>
                    {options_buttons && <CMP_OPTIONSBUTTONS {...options_buttons.props} />}
                </div>

                {!hide_tablecontrols && <div className='table__options__search'>
                    <CMP_SEARCH search_term={var_internal_filter.search_term} onChange={onChange_search} />
                    <CMP_REFRESH loading={loading} onClick={onClick_refresh} tableid={props.id}/>
                    <CMP_EXPORT loading={loading} totalrows={totalrows} downloadname={downloadname} downloadfunction={downloadfunction} downloadconfig={export_downloadconfig} tableid={props.id}/>
                </div>
                }
            </div>

            {limit > 10 && !hide_tablecontrols &&
                <div className='table__controls table__controls--top'>
                    <div className={'table__controls--desktop'}>
                        <CMP_ROWSPERPAGE limit={limit} onChange={onChange_rowsperpage} />
                    </div>

                    <CMP_DISPLAYROWS ready={ready} offset={offset} limit={limit} totalrows={totalrows} />

                    <CMP_PAGINATION ready={ready} offset={offset} limit={limit} totalrows={totalrows} onChange={onChange_page} />
                </div>
            }

            <div className='table__wrapper'>
                {var_internal_filter.filters.length > 0 &&
                    <div className='clear__filter'>
                        <div className='text--sm-regular'>{t('Filters have been applied to this table.')} &nbsp;</div>
                        <div className='text--sm-regular text--anchor' onClick={clear_filters} onKeyDown={onKeyDown_clear_filters} tabIndex='0'>{t('Clear all filters.')}</div>
                    </div>
                }

                <SemanticTable className={(className || '') + (loading ? ' tbl_loading' : '')} {...props} fixed sortable={fields.some(item => !!item)}>

                    <Header onMouse_down={onMouseDown}
                            custom_props={custom_props}
                            hide_tablecontrols={hide_tablecontrols}
                            {...header_row.props} />
                        {(!ready && !loadingerror)
                            ?   <Body custom_props={custom_props} hide_tablecontrols={hide_tablecontrols}>
                                    {[...Array(10)].map((item, i) =>
                                        <Row key={'skeleton_row_' + i} hide_tablecontrols={hide_tablecontrols}>
                                            {[...Array(column_count)].map((item, j) =>
                                                <Cell key={'skeleton_cell_' + i + '_' + j} ><div className='skeleton__cell'/></Cell>
                                            )}
                                        </Row>
                                    )}
                                </Body>
                            : (ready && totalrows > 0 && body)
                            ? <Body custom_props={custom_props} hide_tablecontrols={hide_tablecontrols} {...body.props} />
                            : null
                        }
                </SemanticTable>

                {loadingerror &&
                    <div className='table__message'>
                        <div className='table__message__text error text--sm-regular'>
                            {t('An unexpected error occurred. Please try reloading.')}
                        </div>
                        <div className='table__message__buttons'>
                            <Button className='secondary' onClick={onClick_refresh}>{t('Reload')}</Button>
                        </div>
                    </div>
                }

                {ready && totalrows === 0 &&
                    <div className='table__message'>
                        <div className='table__message__text text--sm-regular'>
                            {t('There are no records to display.')}
                        </div>
                    </div>
                }
            </div>

            {!hide_tablecontrols &&
                <div className='table__controls table__controls--bottom'>
                    <div className={'table__controls--desktop'}>
                        <CMP_ROWSPERPAGE limit={limit} onChange={onChange_rowsperpage}/>
                    </div>

                    <CMP_DISPLAYROWS ready={ready} offset={offset} limit={limit} totalrows={totalrows} />

                    <CMP_PAGINATION ready={ready} offset={offset} limit={limit} totalrows={totalrows} onChange={onChange_page} />
                </div>
            }
        </>
    );
}

function Header({onMouse_down, custom_props, hide_tablecontrols, ...props}) {
    let row = props.children;
    let newprops = {...props};
    newprops.children = null;
    return (
        <SemanticTable.Header {...newprops}>
            <Row onMouse_down={onMouse_down}
                 custom_props={custom_props}
                 hide_tablecontrols={hide_tablecontrols}
                 {...row.props} />
        </SemanticTable.Header>
    )
}

function Row({onMouse_down, custom_props, hide_tablecontrols, ...props}) {
    let newprops = {...props};
    newprops.children = null;
    return (
        <SemanticTable.Row {...newprops}>
            {
                props.children.type?.name === HeaderCell.name
                ? <HeaderCell onMouse_down={onMouse_down}
                            custom_props={custom_props}
                            lock={custom_props && 0 < custom_props.lockcolumns}
                            hide_tablecontrols={hide_tablecontrols}
                            {...props.children.props}
                            key={'HeaderCell_0'} />
                : props.children.type?.name === Cell.name
                ? <Cell custom_props={custom_props} lock={custom_props && 0 < custom_props.lockcolumns} {...props.children.props} field={custom_props.fields[0]} key={'Cell_0'}/>
                : <>
                    {props.children.filter(item => item.type?.name === HeaderCell.name).map((item, index) =>
                        <HeaderCell onMouse_down={onMouse_down}
                            custom_props={custom_props}
                            lock={custom_props && index < custom_props.lockcolumns}
                            hide_tablecontrols={hide_tablecontrols}
                            {...item.props}
                            key={'HeaderCell_' + index} />
                    )}
                    {props.children.filter(item => item.type?.name === Cell.name).map((item, index) =>
                        <Cell custom_props={custom_props} lock={custom_props && index < custom_props.lockcolumns} {...item.props} field={custom_props.fields[index]} key={'Cell_' + index}/>
                    )}
                </>
            }
        </SemanticTable.Row>
    )
}

function HeaderCell({onMouse_down, custom_props, className, field, datatype, filtertype, lock, hide_tablecontrols, ...props}) {

    const { t } = useTranslation('public');
    const [ var_filteropen, set_filteropen ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    useEffect(() => {
        if (!var_filteropen && var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_filteropen])

    let newprops = {...props};
    newprops.children = null;

    //  set first column to 33% when in mobile view
    let responsiveWidth = 0;
    if (lock && custom_props.windowsize.width <= 744) {
        responsiveWidth = (custom_props.windowsize.width/3);
        if (document.querySelector('.lock-first-column .table__headercell_text span')) {
            let minWidth = document.querySelector('.lock-first-column .table__headercell_text span').offsetWidth + 72;
            if (responsiveWidth < minWidth) {
                responsiveWidth = minWidth;
            }
        }
    }

    function onClick_filter(e) {
        e.stopPropagation();
        e.preventDefault();
        set_filteropen(true);
        set_focuselement(document.activeElement);
    }

    return (
        <SemanticTable.HeaderCell aria-sort={custom_props?.sortby === field ? custom_props.sortorder : null} scope='col' className={`${className || ''} text--xs-medium header_${field || 'no_field'}${lock ? ' lock-first-column' : ''}`} {...newprops} >
            <div className='table__headercell' id={field || 'no_field'} style={{width: (responsiveWidth > 0) ? responsiveWidth: 'unset'}}>
                <div className='table__headercell_text' onClick={() => field && custom_props.onChange_sort(field)}>
                    <span>{props.children}</span>
                    {field && !hide_tablecontrols &&
                        <Popup trigger={
                                <div className='table__filter'>
                                    <Button type='button' aria-label={t('Open filter menu')} onClick={onClick_filter}>
                                        <Icon name='filter_modal' className='icon icon__filter' alt='' />
                                    </Button>
                                </div>
                            }
                            content={
                                var_filteropen
                                ? <CMP_FILTER custom_props={custom_props} is_open={var_filteropen}
                                        field={field} datatype={datatype} filtertype={filtertype}
                                        populatefilterfunction={custom_props.populatefilterfunction} filterinformation={custom_props.filterinformation}
                                        onHide={() => set_filteropen(false)} onSort={custom_props.onChange_sort} onFilterChange={custom_props.onChange_filter}
                                        />
                                : null
                            }
                            on='click'
                            open={var_filteropen}
                            onOpen={(event) => { event.stopPropagation(); set_filteropen(true); }}
                            onClose={(event) => { event.stopPropagation(); set_filteropen(false); }}
                            onClick={(event) => { event.stopPropagation(); }}
                            hideOnScroll={true}
                            position='bottom left'
                            className='popup__filter' />
                    }
                </div>
                <div className='table__resizer' draggable='true' onMouseDown={e => {onMouse_down(e, field || 'no_field')}}/>
            </div>
        </SemanticTable.HeaderCell>
    )
}
function Body({custom_props, hide_tablecontrols, ...props}) {
    let newprops = {...props};
    newprops.children = null;
    return (
        <SemanticTable.Body {...newprops}>
            {props.children.filter(item => item.type?.name === Row.name).map((item, i) =>
                <Row custom_props={custom_props} {...item.props} key={'Row_' + i} hide_tablecontrols={hide_tablecontrols} />
            )}
        </SemanticTable.Body>
    )
}
function Cell({custom_props, className, field, lock, ...props}) {
    return (
        <SemanticTable.Cell className={`${className || 'text--sm-regular'} data_${field}${lock ? ' lock-first-column' : ''}`} {...props} />
    )
}

Table.Header = Header;
Table.Row = Row;
Table.HeaderCell = HeaderCell;
Table.Body = Body;
Table.Cell = Cell;
Table.OptionsButtons = CMP_OPTIONSBUTTONS;
Table.CellContentTwoLine = CMP_CELLCONTENT_TWOLINE;

Table.propTypes = {
    id:propTypes.string,
    className: propTypes.string,
    loading: propTypes.bool.isRequired,
    ready: propTypes.bool.isRequired,
    loadingerror: propTypes.bool,
    lockcolumns: propTypes.number,
    refresh: propTypes.bool,
    totalrows: propTypes.number.isRequired,
    populateconfig: propTypes.shape({
        maxfilteritems: propTypes.number.isRequired,
        limit: propTypes.number.isRequired,
        offset: propTypes.number.isRequired,
        sortby: propTypes.string.isRequired,
        sortorder: propTypes.oneOf(['ascending', 'descending']).isRequired,
        filter: propTypes.object.isRequired
    }).isRequired,
    populatefilterfunction: propTypes.func.isRequired,
    downloadname: propTypes.string.isRequired,
    downloadfunction: propTypes.func.isRequired,
    onChange: propTypes.func.isRequired
};

HeaderCell.propTypes = {
    field: propTypes.string,
    datatype: propTypes.oneOf(['date', 'datetime', 'number', 'text']),
    filtertype: propTypes.oneOf(['date', 'number', 'option', 'text'])
};

export default Table;